.link-underline {
    display: inline-block;
    position: relative;
    padding-bottom: .125rem;
    overflow: hidden;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    vertical-align: middle;
    transition: color .25s cubic-bezier(.28, .44, .49, 1);

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        width: 100%;
        will-change: transform;
        background-color: currentColor;
        opacity: .35;
    }

    &:hover {
        &::after {
            opacity: 1;
            animation: scaleOut .25s cubic-bezier(.28, .44, .49, 1) 0s forwards, scaleIn .25s cubic-bezier(.28, .44, .49, 1) .25s forwards;
        }
    }
}

.link-decoration {
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

.link-hover-decoration {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

//Link hover underline

.link-hover-underline {
    position: relative;
    display: inline-block;

    &::after {
        content: "";
        display: block;
        position: absolute;
        height: 1px;
        top: 100%;
        left: .05em;
        right: .05em;
        background: currentColor;
        transform-origin: 100% 50%;
        transform: scaleX(0);
        transition: -webkit-transform .6s cubic-bezier(1, 0, 0, 1);
        transition: transform .6s cubic-bezier(1, 0, 0, 1);
       }

    &:hover {
        &::after {
            transform: scaleX(1);
            transition-duration: .9s;
            transition-timing-function: cubic-bezier(.19, 1, .22, 1);
            transform-origin: 0 50%;
        }
    }

}

.link-decoration:not(:hover):not(.active) {
    text-decoration: underline;
}

.link-hover-move-arrow {
    position: relative;
    display: inline-block;
    transition: padding .35s cubic-bezier(0.68, -0.55, 0.265, 1.55);

    svg {
        width: 10px;
        height: auto;
        margin-right: 2px;

        >path {
            fill: currentColor;
        }
    }

    &:hover {
        padding-left: .5rem;
    }
}

//link-both-underline
.link-both-underline {
    display: inline-block;
    text-decoration: none;
    position: relative;
    overflow: hidden;

    &::after {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        transform: translate3d(-102%, 0, 0);
        content: '';
        display: block;
        height: 1px;
        background-color: currentColor;
    }

    &:hover {
        &::after {
            animation: underline-both 1s ease;
        }
    }
}

//Link hover no underline
.link-hover-no-underline{
    display:inline-block;
    position:relative;
    padding-bottom: .02em;
    &::after{
        content: "";
        position: absolute;
        bottom: 0;
        height: 1px;
        background: currentColor;
        width: 100%;
        right: 0;
        left: 0;
        transition: all .5s cubic-bezier(.23,1,.32,1);
    }
    &:hover{
        &:after{
            width: 0;
            left: 50%;
            right: auto;
        }
    }
}

//Link hover image reavel
/* Recommended styles for Splitting */
.splitting .word,
.splitting .char {
    display: inline-block;
}

/* Psuedo-element chars */
.splitting .char {
    white-space: nowrap;
    position: relative;
}

.link-underline,
.link-hover-underline,
.btn,
.nav-link {
    >* {
        pointer-events: none;
    }
}

//multi-line
.link-multiline {
    display: inline;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 0% 1px;
    transition: background-size 0.3s, color .3s;
    &:hover {
        background-size: 100% 1px;
    }
}
.link-2x{
    &.link-underline,&.link-hover-underline,&.link-hover-nounderline,&.link-both-underline{
        padding-bottom: .2rem;
        &::after{
            height: 2px;
        }
    }
}
//link-arrow-bounce
.link-arrow-bounce{
    animation-name: bounce;
animation-iteration-count: infinite;
animation-duration: 1s;
animation-delay: 2s;
animation-fill-mode: forwards;
}
//flatpickr plugin custom css
.flatpickr-calendar {
  background-color: var(--#{$prefix}tertiary-bg);
  border-color:1px solid var(--#{$prefix}border-color);
  box-shadow: none;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {

  background-color: var(--#{$prefix}primary);
  border-color: var(--#{$prefix}primary);
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  background-color:var(--#{$prefix}secondary-bg);
  border-color: var(--#{$prefix}border-color);
}

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 tint-color($primary, 90%), 5px 0 0 tint-color($primary, 90%);
  box-shadow: -5px 0 0 tint-color($primary, 90%), 5px 0 0 tint-color($primary, 90%);
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color:var(--#{$prefix}border-color-translucent);
}

.flatpickr-calendar.arrowTop::before,
.flatpickr-calendar.arrowTop::after {
  border-bottom-color: var(--#{$prefix}border-color);
}

span.flatpickr-weekday,
.numInputWrapper span {
  color: var(--#{$prefix}body-color);
}

.flatpickr-current-month {
  font-size: $font-size-base;
}

.numInputWrapper span.arrowUp::after {
  border-bottom-color: var(--#{$prefix}border-color);
}

.numInputWrapper span.arrowDown::after,.flatpickr-calendar.arrowBottom::after {
  border-top-color: var(--#{$prefix}border-color);
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background-color: tint-color($primary, 90%);
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  border-radius: $border-radius;
  color:var(--#{$prefix}body-color);
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  fill: var(--#{$prefix}body-color);
  color:var(--#{$prefix}body-color);
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 34px;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: $primary;
}

.flatpickr-day {
  color: var(--#{$prefix}body-color);
}

.flatpickr-day.today {
  border-color: $gray-300;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color:var(--#{$prefix}secondary-bg);
  ;
  background:var(--#{$prefix}secondary-bg);
  color:var(--#{$prefix}body-color);
  ;
}
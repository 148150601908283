// Buttons style
.btn {
  text-transform: $btn-text-transform;
  letter-spacing: $btn-letter-spacing;
  font-weight: $btn-font-weight;
  --#{$prefix}btn-focus-box-shadow: 0 6px 30px -3px rgba(var(--#{$prefix}btn-focus-shadow-rgb), .325);
  &:hover{
    box-shadow:var(--#{$prefix}btn-focus-box-shadow);
  }
}


//
// Alternate buttons
//


//Buttons rise
.btn-rise {
  overflow: hidden;
  position: relative;
  transition: all .3s;
  box-shadow: none;
  z-index: 0;

  &.p-0 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
  }

  .btn-rise-bg {
    z-index: -1;
    display: block;
    width: 125%;
    height: 100%;
    position: absolute;
    background-color: currentColor;
    top: 0;
    left: 50%;
    pointer-events: none;
    transform: translate(-50%, 105%);
    border-radius: 50%;
    transition: transform .65s cubic-bezier(.77, 0, .175, 1), border-radius .65s cubic-bezier(.77, 0, .175, 1);
  }

  .btn-rise-text {
    position: relative;
    transition: color .4s;
  }

  &[class^="btn-outline-"],
  &[class*="btn-outline-"] {

    &:hover,
    &:focus,&:focus,
    &:active,
    &.active {
      background-color: transparent!important;
    }
  }

  &:hover,&:focus,
  &.active,
  &.focus {

    &:not(:disabled):not(.disabled) {
      .btn-rise-bg {
        transform: translate(-50%, 0);
        border-radius: 0;
      }
    }
  }
}


//btn hover-arrow
.btn-hover-arrow {
  position: relative;
  overflow: hidden;
 display: inline-flex;
 align-items: center;
 justify-content: center;
  span {
    display: inline-block;
    transform: translate3d(12px, 0, 0);
    transition: transform 0.3s cubic-bezier(0.42, 0.01, 0.23, 1);
  }

  &::after {
    vertical-align: middle;
    content: "\ebe6";
    font-family:boxicons!important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    line-height: 24px;
    font-size: $font-size-base * 1.25;
    width: 24px;
    height: 24px;
    display: inline-block;
    opacity: 0;
    transform: translate3d(12px, 0, 0);
    transition: opacity 0.3s, transform 0.4s cubic-bezier(0.42, 0.01, 0.23, 1);
  }

  &:hover {
    span {
      transform: translate3d(0,0,0);
    }

    &::after {
      transform: translate3d(6px, 0, 0);
      opacity: 1;
    }
  }

  &.btn-lg {
    &::after {
      font-size: $font-size-base * 1.375;
    }

    &:hover {
      span {
        transform: translate3d(-6px, 0, 0);
      }
    }
  }
  &.btn-sm {
    &::after {
      font-size: $font-size-base * 1.125;
    }

    &:hover {
      span {
        transform: translateZ(0px);
      }
    }
  }
}


//btn-ripple
.btn-circle-ripple {
  position: relative;

  &::before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    box-shadow:0 0 0 1px currentColor;
    animation: pulse 2s infinite ease-in-out;
  }
}

@mixin btn-hover-ripple($color: $theme-colors) {
  box-shadow:0 0 0 1px $color !important;
}

@each $color,
$value in $theme-colors {

  .btn-circle-ripple.btn-#{$color},
  .btn-circle-ripple.btn-outline-#{$color} {
    &::before{
      @include btn-hover-ripple($value);
    }
  }
}


//buttons hover text
.btn-hover-text {
  position: relative;
  .btn-hover-label.label-default {
    display: block;
    transition: transform .5s cubic-bezier(.5, 0, 0, 1), opacity .5s cubic-bezier(.5, 0, 0, 1);
    transition-delay: .1s;
  }

  .btn-hover-label.label-hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(1vw);
    opacity: 0;
    transition: transform .5s cubic-bezier(.5, 0, 0, 1), opacity .5s cubic-bezier(.5, 0, 0, 1);
    transition-delay: 0s;
  }

  &:hover {
    .btn-hover-label.label-default {
      transform: translateY(-1vw);
      opacity: 0;
      transition-delay: 0s;
    }

    .btn-hover-label.label-hover {
      transform: translateY(0);
      opacity: 1;
      transition-delay: .1s;
    }
  }
}